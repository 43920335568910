.year_end {
    background-color: #fbf6ec;
    display: flex;
    flex-direction: column;
}

.happyholidays {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: auto;
}

p {
    z-index: 1000;
    padding-left: 10%;
    padding-right: 10%;
    color: #292929;
    background-color: #fbf6ec;
}

.photo {
    width: 100%;
    height: auto;
    display: block;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #fbf6ec;
  }
  
  .photocontainer {
    position:relative;
    width: 100%;
  }

  .photocontainer:hover .overlay {
    opacity: .8;
  }

  .photocontainer .overlay {
    opacity: 0;
  }

  .phototext {
    color: #292929;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }